<template>
  <div v-if="isLoading" class="spinner-wrapper bg-white">
    <lottie-animation
        :width=300
        :height=300
        path="animation/freta-loading.json"
      />
  </div>
  <div
    v-else-if="$store.state.cart.cartData.items.length == 0"
    style=""
    class="empty-list d-flex justify-content-center align-items-center p-5 flex-column"
  >
    <img
      src="../assets/img/empty-list.svg"
      alt=""
      style="max-width: 100%"
    />
    <h3 class="my-4">
      Seu orçamento esta vazio!
    </h3>
    <h5><a href="/">Clique aqui</a> para voltar e
      realizar uma busca</h5>
  </div>
  <div v-else id="checkout" :style="cssProps">
    <div class="container" style="">
      <div class="row">
        <div
          class="col-12 col-xxl-8 offset-xxl-2 p-0"
          v-if="this.$store.state.cart.cartData"
        >
          <div class="col-md-12 py-2">

            <div class="cta row align-items-center">
              <div class="col-lg-9 col-12">
                <h2>
                  Confira seu pedido, <br />
                  insira as informações de pagamento e pronto!
                </h2>
              </div>
              <div class="col-lg-3 col-12 justify-content-lg-end justify-content-center d-flex pr-0 mr-0 mt-lg-0 mt-2">
                <a href="/search-results" class="btn btn-outline-white">
                  Voltar para a busca
                </a>
              </div>
            </div>

          </div>

          <div class="col-md-12" v-if="this.checkoutError">
            <div class="alert alert-danger" role="alert">
              {{this.checkoutError}}
            </div>
          </div>

          <div class="col-md-12">
             <div
                v-for="(item, index) in $store.state.cart.cartData.items"
                :key="index"
              >
                <div v-if="item">
                    <trip-item
                    :item="item"
                    :index="index"
                    @reload="reloadData"
                  ></trip-item>
                </div>
              </div>

              <div v-if="this.$store.state.cart.cartData.discount" class="cart cupom-details mb-3">
                  <div class="row align-content-center justify-content-between checkout-total">
                    <div class="col-lg-6 col-12 pl-0 mb-3 mb-lg-0 d-flex align-items-center">
                      <h2 class="mt-2">Desconto aplicado</h2>
                    </div>

                    <div class="col-lg-6 col-12 pl-0 mb-3 mb-lg-0 text-right">
                      <h2 class="mt-2">Valor do desconto</h2>
                      <h1>{{this.$store.state.cart.cartData.discount | currency}}</h1>
                    </div>
                  </div>
              </div>

              <div v-else-if="this.$store.state.cart.cartData.coupon" class="cart cupom-details mb-3">

                <div class="row align-content-center justify-content-between checkout-total">
                  <div class="col-lg-6 col-12 pl-0 mb-3 mb-lg-0">
                    <h2 class="mt-2">Nome do cupom</h2>
                    <h1>{{this.$store.state.cart.cartData.coupon.name}}</h1>
                  </div>

                  <div class="col-lg-6 col-12 pl-0 mb-3 mb-lg-0 text-right">
                    <h2 class="mt-2">Valor do desconto</h2>
                    <h1>{{this.getCupomDiscount() | currency}}</h1>
                  </div>

                <!-- <div class="row">
                  <div class="col-xl-12">
                    <div class="content d-flex justify-content-between">
                      <div class="name d-flex flex-column">
                        <span>Nome do cupom</span>
                        {{this.$store.state.cart.cartData.coupon.name}}
                      </div>

                      <div class="discount d-flex flex-column">
                        <span>Valor do desconto</span>
                        
                      </div>

                    </div>
                  </div>
                </div> -->
              </div>
            </div>
            <div class="cart">
              <div class="row align-content-center checkout-total">
                <div class="col-lg-4 col-12 pl-0 mb-3 mb-lg-0">
                  <h2 class="mt-2">Total a Pagar</h2>
                  <h1>{{ this.$store.state.cart.cartData.total | currency }}</h1>
                </div>
                <div v-if="!this.$store.state.cart.cartData.discount > 0" class="col-lg-8 col-12 p-0 align-items-lg-center d-lg-flex"
                >
                <div class="col-md-7 col-12">
                  <div :class="{
                      'has-feedback': errors.coupon,
                      'has-success': this.$store.state.cart.cartData.coupon,
                    }"
                  >
                    <input
                      v-model="couponName"
                      type="text"
                      style=""
                      class="form-control pr-3"
                      :style="{ border: primaryColor }"
                      placeholder="Insira o código do cupom"
                    />
                    <div class="invalid-feedback" v-if="errors.coupon">
                      {{ this.cupomAlert('error', errors.coupon) }}
                    </div>
                  </div>
                </div>
                <div class="col-md-5 col-12">
                  <button
                    v-if="!this.$store.state.cart.cartData.coupon"
                    @click="applyCoupon"
                    class="btn-coupon"
                  >
                    <font-awesome-icon icon="plus"></font-awesome-icon>
                    Adicionar Cupom
                  </button>
                  <button
                    v-else
                    @click="removeCoupon"
                    class="btn btn-coupon ml-2"
                  >
                    <font-awesome-icon icon="minus"></font-awesome-icon>
                    Remover
                  </button>
                </div>
                  <div
                    class="has-success mt-3"
                    v-if="this.$store.state.cart.cartData.coupon"
                  >
                    <small>{{ this.cupomAlert('success', errors.coupon) }}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 mt-3">
            <div
              class="card checkout-login"
              style="background: white"
              v-if="!this.user"
              ref="login"
            >
              <h4>Você precisa acessar sua conta para concluir a reserva</h4>
              <div class="btn btn-outline">
                <login-modal :style="{color: this.$store.state.companyDetails.whitelabel.body_font_color}" type='result-list' @close="closeModal" />
              </div>
            </div>
            <div class="card" v-if="validate">
              <div class="address-data">
                <div class="block-address-form" v-if="blockAddressForm">
                  <b-spinner label="Carregando..."></b-spinner>
                </div>
                <div class="personal-data">
                  <h3>Dados pessoais</h3>
                  <div
                    :class="{
                      'form-group mb-4': true,
                      'has-feedback': cc_errors.cnpj_cpf,
                    }"
                    v-if="this.user.type_people == '1' || !this.user.type_people || this.user.type_people == 'lead'"
                  >
                    <label for="">CPF</label>
                    <the-mask mask="###.###.###-##" class="form-control input100" v-model="credentials.cnpj_cpf" placeholder="000.000.000-00"
                    @change.native="checkAddressIsComplete('doc')" />
                  </div>


                  <div
                    :class="{
                      'form-group mb-4': true,
                      'has-feedback': cc_errors.cnpj_cpf,
                    }"
                    v-if="this.user.type_people == '2' "
                  >
                    <label for="">CNPJ</label>
                    <the-mask mask="##.###.###/####-##" class="form-control input100" v-model="credentials.cnpj_cpf" placeholder="00.000.000/0000-00"
                    @change.native="checkAddressIsComplete()" />
                  </div>

                  <div
                    :class="{
                      'form-group mb-4': true,
                      'has-feedback': cc_errors.tel,
                    }"
                  >
                    <label for="">Telefone</label>
                    <the-mask :mask="['(##) ####-####', '(##) #####-####']"
                      class=" form-control"
                      v-model="credentials.tel"
                      v-on:change="checkAddressIsComplete()"
                    />
                  </div>

                </div>

                <h3>Endereço</h3>
                <p>Preencha os seus dados de endereço para continuar</p>
                <div class="row">

                  <div class="col-md-12">
                    <div class="row">
                      <div
                        :class="{
                          'col-md-12 form-group': true,
                          'has-feedback': cc_errors.cep,
                        }"
                      >
                        <label for="">Cep</label>
                        <input
                          class="form-control mb-2"
                          v-model="credentials.cep"
                          type="tel"
                          maxlength="8"
                          @input="sanitizeInput"
                          @keyup="searchCep()"
                          v-on:change="checkAddressIsComplete()"
                        />

                      </div>
                      <div
                        :class="{
                          'col-md-4 form-group': true,
                          'has-feedback': cc_errors.address,
                        }"
                      >
                        <label for="">Endereço</label>
                        <input
                          class="form-control mb-2"
                          v-model="credentials.address"
                          type="text"
                          v-on:change="checkAddressIsComplete()"
                        />

                      </div>
                      <div
                        :class="{
                          'col-md-4 form-group': true,
                          'has-feedback': cc_errors.complement,
                        }"
                      >
                        <label for="">Complemento</label>
                        <input
                          class="form-control mb-2"
                          v-model="credentials.complement"
                          type="text"
                          v-on:change="checkAddressIsComplete()"
                        />

                      </div>
                      <div
                        :class="{
                          'col-md-4 form-group': true,
                          'has-feedback': cc_errors.number_address,
                        }"
                      >
                        <label for="">Número</label>
                        <input
                          class="form-control mb-2"
                          v-model="credentials.number_address"
                          v-on:change="checkAddressIsComplete()"
                          type="number"
                        />
                      </div>
                      <div
                        :class="{
                          'col-md-4 form-group': true,
                          'has-feedback': cc_errors.neighborhood,
                        }"
                      >
                        <label for="">Bairro</label>
                        <input
                          class="form-control mb-2"
                          v-model="credentials.neighborhood"
                          v-on:change="checkAddressIsComplete()"
                          type="text"
                        />
                      </div>
                      <div
                        :class="{
                          'col-md-4 form-group': true,
                          'has-feedback': cc_errors.city,
                        }"
                      >
                        <label for="">Cidade</label>
                        <input
                          class="form-control mb-2"
                          v-model="credentials.city"
                          v-on:change="checkAddressIsComplete()"
                          type="text"
                        />
                      </div>
                      <div
                        :class="{
                          'col-md-4 form-group': true,
                          'has-feedback': cc_errors.state,
                        }"
                      >
                        <label for="">Estado</label>
                        <input
                          class="form-control mb-2"
                          v-model="credentials.state"
                          v-on:change="checkAddressIsComplete()"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div :class="{'card payment-data': true, 'block-ui' : !this.user}">
              <div class="block-payment"  v-if="!this.user"></div>
              <header class="row justify-content-between px-3">
                <div class="title">
                  <h2 class="card-title">Dados de pagamento</h2>
                  <p class="title-description">Escolha o método de pagamento</p>
                </div>
                <div class="icon d-none d-lg-flex">
                  <p>Ambiente 100% seguro</p>
                  <img src="@/assets/img/secure-checkout.png" alt="Ambiente seguro">

                </div>
              </header>
              <div class="payment-methods">
                <div class="row">
                  <div class="col-lg-3 col-12 payment">
                   <div :class="[true ? 'box' : '', methodPayment == 'bank_slip' ? 'active' : '']"
                   @click="paymentMethod('bank_slip', true)">
                    <div class="title">
                      Boleto ou PIX à vista
                    </div>
                   </div>
                  </div>
                  <div class="col-lg-3 col-12 payment">
                    <div class="title">
                      <div
                      :class="[true ? 'box' : '', this.differenceDays >= 30 ? '' : 'inactive', methodPayment == 'bank_slip_parcel_2' ? 'active' : '']"
                      :title="this && this.differenceDays >= 30 ? '' : 'A data da sua viagem não permite esta forma de pagamento'"
                      @click="paymentMethod('bank_slip_parcel_2', differenceDays >= 30)">
                        {{ this.$store.state.companyDetails.two_times_bankslip_upfront_payment }}% à vista
                        {{ this.differenceDays >= 30 ? 'e saldo até o dia' : 'mais saldo'}}<br>
                        {{ this.differenceDays >= 30 ? tripStartDateFormatted : ''}}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-12 payment">
                    <div class="title">
                      <div
                      :class="[true ? 'box' : '', Object.keys(bank_slip_installments).length > 0 && this.differenceDays >= 30 ? '' : 'inactive' , methodPayment == 'bank_slip_parcel' ? 'active' : '']"
                      :title="Object.keys(bank_slip_installments).length > 0 && this.differenceDays >= 30 ? '' : 'A data da sua viagem não permite esta forma de pagamento'"
                      @click="paymentMethod('bank_slip_parcel', Object.keys(bank_slip_installments).length > 0 && differenceDays >= 30)">
                        Boleto Parcelado <br/>{{  Object.keys(bank_slip_installments).length > 0 && this.differenceDays >= 30 ? 'em até ' + Object.keys(bank_slip_installments).length + 'x sem juros' : ''}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row w-100 m-0">
                  <div class="payment-information w-100">
                   <div class="col-12 p-0">
                    <div class="info" v-if="methodPayment == 'bank_slip'">
                      <div class="description">Você receberá o boleto por e-mail e poderá pagar em qualquer banco, casa lotérica ou através do seu app de banco. Também poderá baixar o boleto no seu pedido que se encontra no menu “Meus pedidos”</div>
                    </div>

                    <div class="info" v-if="methodPayment == 'bank_slip_parcel_2'">
                      <div class="description">Ao finalizar a compra você receberá um e-mail para efetuar o pagamento do sinal e poderá optar por pagar via Pix, boleto ou transferência bancária.<br>Também poderá baixar o boleto do sinal e boleto do saldo no seu pedido que se encontra no menu “Meus pedidos”</div>
                    </div>

                    <div class="info" v-if="methodPayment == 'bank_slip_parcel'">
                      <div class="description">Ao finalizar a compra você receberá um e-mail com o boleto para efetuar o pagamento da primeira parcela.<br>Também poderá baixar o boleto da primeira e das demais parcelas no seu pedido que se encontra no menu “Meus pedidos”</div>
                      <div class="row">
                        <div
                          :class="{
                            'col-md-12 mb-3 installments form-group': true,
                            'has-feedback': cc_errors.bank_slip_installment,
                          }"
                        >
                          <label for="bank-slip-installments"
                          class="mt-2"
                            >Número de parcelas</label
                          >
                          <select
                            name="bank-slip-installments"
                            class="form-control"
                            v-model="bank_slip_installment"
                            required
                          >
                            <option
                              v-for="(
                                label, bs_installment
                              ) in bank_slip_installments"
                              :value="bs_installment"
                              :key="bs_installment"
                            >
                              {{ label }}
                            </option>
                          </select>
                          <div
                            class="invalid-feedback"
                            v-if="cc_errors.bank_slip_installment"
                          >
                            {{ cc_errors.bank_slip_installment }}
                          </div>
                        </div>
                      </div>
                    </div>
                   </div>
                  </div>
                </div>
              </div>
              <b-tabs pills content-class="mt-2" v-model="tabIndex">
                <b-alert v-if="errorBuy" show variant="danger"
                  >Não foi possível finalizar a compra!</b-alert
                >

                <div class="row">

                  <div
                    :class="{
                      'col-md-12 form-group d-flex align-items-center': true,
                      'has-feedback': cc_errors.agree_terms,
                    }"
                  >
                    <label class="agree-driver-expenses" for="agree-driver-expenses">
                      <input
                        type="checkbox"
                        name="agree-terms"
                        v-model="agree_terms"
                        id="agree-driver-expenses"
                        ref="agree_terms"
                        class="font-weight-bold"
                        v-on:change="checkAddressIsComplete()"
                      />

                      <a
                        href="javascript:void(0)"
                        @click="showModal('terms-of-use')"
                        > LI E ACEITO OS TERMOS, CONDIÇÕES DE USO E POLITICA DE PRIVACIDADE</a
                      >
                    </label>
                    <div
                      class="invalid-feedback parsley-required mb-3"
                      v-if="cc_errors.agree_terms"
                    >
                      {{ cc_errors.agree_terms }}
                    </div>
                  </div>
                </div>

                <div class="row">

                  <div
                    :class="{
                      'col-md-12 form-group d-flex align-items-center': true,
                      'has-feedback': cc_errors.agree_service_terms,
                    }"
                  >
                    <label class="agree-driver-expenses" for="agree-driver-expenses">
                      <input
                        type="checkbox"
                        name="agree-terms"
                        v-model="agree_service_terms"
                        id="agree-driver-expenses"
                        ref="agree_service_terms"
                        class="font-weight-bold"
                        v-on:change="checkAddressIsComplete()"
                      />

                      <a
                        href="javascript:void(0)"
                        @click="showModal('terms-of-service')"
                        > LI E ACEITO AS CONDIÇÕES DO CONTRATO DE PRESTAÇÃO DE SERVIÇO.</a
                      >
                    </label>
                    <div
                      class="invalid-feedback parsley-required mb-3"
                      v-if="cc_errors.agree_service_terms"
                    >
                      {{ cc_errors.agree_service_terms }}
                    </div>
                  </div>
                </div>
                <a
                  @click="sendOrder"
                  :class="[true ? 'btn p-3 mt-4 w-100 btn btn-primary btn-checkout' : '', this.validateComplete ? '' : 'disabled']"
                  >RESERVAR</a
                >
              </b-tabs>
              <b-modal
                id="terms-of-use"
                ref="terms-of-use"
                title="Termos de Uso"
              >
                <div v-html="this.$store.state.companyDetails.terms"></div>
                <template v-slot:modal-ok><button class="modalBtn" @click="termsAgree()">Aceitar</button></template>
              </b-modal>

              <b-modal
                id="terms-of-service"
                ref="terms-of-service"
                title="Termos de Prestação de Serviço"
              >
              <div v-html="this.$store.state.companyDetails.service_terms"></div>
                <template v-slot:modal-ok><button class="modalBtn" @click="termsServiceAgree()">Aceitar</button></template>
              </b-modal>

            </div>
          </div>
        </div>
      </div>
      <!-- <div v-else>
        <div class="col-12 col-xxl-8 offset-xxl-2 d-flex justify-content-center flex-column align-items-center" style="min-height: 78.7vh">
                <h2>SEU CARRINHO ESTÁ VAZIO!</h2>
                <h3>Parece que você não agendou nenhum fretamento até agora</h3>
        </div>
    </div> -->
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import axios from 'axios'
import TripItem from '@/components/general/TripItem.vue'
import DailyItem from '@/components/general/DailyItem.vue'
import TransferItem from '@/components/general/TransferItem.vue'
import LoginModal from '@/components/modals/LoginModal.vue'
import SearchCardItem from '@/components/search-results/SearchCardItem.vue'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'

export default {
  name: 'Checkout',
  components: { TripItem, DailyItem, TransferItem, LoginModal, SearchCardItem, LottieAnimation },
  metaInfo() {
    return {
      titleTemplate: '%s - Checkout',
    }
  },
  data () {
    return {
      checkoutError: '',
      blockAddressForm: false,
      checkout: null,
      validateComplete: false,
      isLoading: false,
      auth_attempt: 'login',
      cc_errors: {},
      canDoCheckout: true,
      errorBuy: false,
      tabIndex: 0,
      couponName: '',
      agree_terms: false,
      agree_service_terms: false,
      paymentHighestDate: false,
      paymentHighestDateFormated: false,
      tripStartDate: false,
      tripStartDateFormatted: false,
      differenceDays: 0,
      emailDate: false,
      actualDate: false,
      startDate: false,
      endDate: false,
      validate: false,
      showLoginModal: false,
      credentials: {
        city: '',
        state: '',
        cep: '',
        neighborhood: '',
        address: '',
        number_address: '',
        complement: ''
      },
      userToRegister: {
        name: '',
        email: '',
        password: '',
        status: 1,
        phone: '',
        cnpj_cpf: '',
        tel: ''
      },
      cardInfo: {
        cardNumber: '5102091896552622',
        holderName: 'Nome do Titular do Cartão',
        securityCode: '000',
        expirationMonth: '12',
        expirationYear: '2045'
        // cardNumber: '',
        // holderName: '',
        // securityCode: '',
        // expirationMonth: '',
        // expirationYear: ''
      },
      form_errors: [],
      bank_slip_installment: null,
      installments: null,
      bank_slip_installments: [],
      credit_card_installments: [],
      methodPayment: 'bank_slip'
    }
  },
  methods: {
    ...mapActions('auth', ['sendLoginRequest']),
    ...mapActions('auth', ['sendRegisterRequest']),
    ...mapActions('cart', ['getInstallments']),
    ...mapActions('cart', ['getBankSlipInstallments']),
    ...mapActions('cart', ['createOrder']),
    ...mapActions('cart', ['clearCart']),
    sanitizeInput() {
      this.credentials.cep = this.credentials.cep.replace(/\D/g, "");
    },
    async reloadData() {
      let cartData = this.$store.state.cart.cartData.items
      let filtered = cartData.filter(function (item) { return item })
      if (cartData.length !== filtered.length) {
        this.$store.state.cart.cartData.items = filtered
      }


      await this.$store.commit('cart/calculateCartTotal', { data: this.$store.state })

      this.dayBeforeTrip()
      await this.getBankSlipInstallments({
        origin_date: this.moment(this.tripStartDate).format('YYYY/MM/DD'),
        total: this.$store.state.cart.cartData.total,
        items: this.$store.state.cart.cartData.items
      })
    },
    removeCoupon () {
      this.$store.commit('cart/removeCoupon', null)
      this.couponName = ''
    },
    getCupomDiscount () {
      return this.$store.state.cart.cartData.discount
    },
    cupomAlert (type, errors) {
      if (type === 'error') {
        this.$swal('Erro', errors[0], 'error')
      } else {
        // this.$swal('Tudo certo!', 'O cupom foi aplicado com sucesso.', 'success')
      }
    },
    async fieldsValidate () {
      await Object.keys(this.credentials).forEach(item => {
        if (this.credentials[item] === '') {
          this.$swal('Erro', 'Preencha todos os campos de endereço', 'error')
          return false
        }
      })

      return true
    },
    update () {
      this.credentials.id = this.user.id
      this.credentials.email = this.user.email
      this.cc_errors = {}
      if (this.fieldsValidate()) {
        axios.post(process.env.API_URL + 'user/update', this.credentials).then((response) => {
          if(response.status === 400) {
            this.$swal('Erro', response.data.message, 'error')
            this.$swal({
              title: 'Erro!',
              text: response.data.message,
              showDenyButton: false,
              showConfirmButton: true,
              confirmButtonText: 'Verificar',
              iconColor: this.$store.state.companyDetails.whitelabel.body_font_color,
              icon: 'error'
            })
          }else{
            this.$store.commit('setUserData', response.data)
            this.userDataUpdate()
          }
          
        })
      }
    },
    userDataUpdate () {
      this.user.address = this.credentials.address
      this.user.cep = this.credentials.cep
      this.user.cnpj_cpf = this.credentials.cnpj_cpf
      this.user.tel = this.credentials.tel
      this.user.city = this.credentials.city
      this.user.complement = this.credentials.complement
      this.user.neighborhood = this.credentials.neighborhood
      this.user.number_address = this.credentials.number_address
      this.user.state = this.credentials.state
    },
    async checkQuoteExiste() {
      if(this.$store.state.cart.cartData.quote_id == null) {
        await this.saveQuote();
      }
    },
    async saveQuote (stock = true) {
     
      let quoteData = {
        availability: stock,
        customer: {
          name: this.$store.state.auth.userData.name,
          company: this.$store.state.auth.userData.company,
          document: this.$store.state.auth.userData.cnpj_cpf,
          email: this.$store.state.auth.userData.email
        },
        customer_id: this.$store.state.auth.userData.id,
        ...this.$store.state.quote.quote
      }

      if(this.$store.state.searchData.id) {
        quoteData['search_id'] = this.$store.state.searchData.id
      }

      let store = this.$store
      await axios.post(process.env.API_URL + 'quote/customer-save', quoteData).then((response) => {
        if (response.status === 200) {
          this.quoted = true
          this.$store.commit('cart/setQuoteID', response.data.quote.id)
        }
     })
   },
    async sendOrderRequest () {
      await this.update();
      if (this.validate) {
        var validation = await this.fieldsValidate()
        if (validation) {
          await this.update()
        } else {
          return false
        }
      }

      if(this.$store.state.cart.cartData.source == '') {
        this.$store.commit('cart/defineCartSource', 'whitelabel');
      }

      await this.checkQuoteExiste();

      this.$store.commit('SET_LOADING_STATE', { value: true, text: 'Aguarde, estamos gerando sua reserva' })
      this.createOrder(this.$store.state.cart.cartData)
        .then((response) => {
          
          if(response.status !== 200){
            this.$store.state.cart.cartData.order_id = response.data.order
            this.validate = true
            this.$store.commit('SET_LOADING_STATE', { value: false })
            this.$swal({
              title: 'Ops!',
              text: response.data.debug.msg || 'Ocorreu um erro ao gerar sua reserva, fale com um de nosso atendentes.',
              showDenyButton: false,
              timer: 4500,
              showConfirmButton: false,
              iconColor: this.$store.state.companyDetails.whitelabel.body_font_color,
              icon: 'error'
            })
          }

          this.$store.commit('SET_LOADING_STATE', { value: false })

          if (response.data.payment) {
            this.$store.commit('cart/setClearCart', {})
            this.$store.state.cart.cartData.order_id = null
            this.$store.state.cart.response = response.data.order
          }

          if (response.data.payment === null) {
            this.$store.state.cart.cartData.order_id = response.data.order
            this.checkoutError = response.data.msg
          } else {
            if (response.status === 200) {
              this.$store.commit('cart/setClearCart', {})
              this.$store.state.cart.cartData.order_id = null
              this.$store.state.cart.response = response.data.order
              this.$router.push({ path: `/pedido-realizado/${response.data.order.id}` })
            }

            if (response.msg == 'Ok') {
              this.$store.commit('cart/setClearCart', {})
            }
          }
        })
        .catch((error) => {
          this.$store.commit('SET_LOADING_STATE', { value: false })
          this.errorBuy = true
        })
    },
    async checkAddressIsComplete ( type = false ) {
      if (!this.validate) {
        var status = true
        if (!this.agree_service_terms || !this.agree_terms) {
          status = false
          this.validateComplete = false
        }

        if (status) {
          this.validateComplete = true
        }
        return true
      }
      status = true
      this.validateComplete = false

      await Object.keys(this.credentials).forEach(item => {
        if (item !== 'complement') {
          if (!this.credentials[item]) {
            status = false
            this.validateComplete = false
          }
        }
      })

      if (!this.agree_service_terms || !this.agree_terms) {
        status = false
        this.validateComplete = false
      }

      if (status) {
        this.validateComplete = true
      }

      if(type && type == 'doc') {
        var docIsValid = this.validateCPF(this.credentials.cnpj_cpf);
        if(!docIsValid) {
          this.$swal({
            title: 'CPF inválido!',
            text: 'Verifique o CPF informado e tente novamente.',
            showDenyButton: false,
            timer: 2500,
            showConfirmButton: false,
            iconColor: this.$store.state.companyDetails.whitelabel.body_font_color,
            icon: 'error'
          })
        }
      }
    },
    validateAddressData () {
      if (this.user) {
        // Update form existing fields
        this.credentials.address = this.user.address
        this.credentials.cep = this.user.cep
        this.credentials.city = this.user.city
        this.credentials.complement = this.user.complement
        this.credentials.neighborhood = this.user.neighborhood
        this.credentials.number_address = this.user.number_address
        this.credentials.state = this.user.state

        if (this.user.cnpj_cpf === null) {
          this.validate = true
        } else {
          axios.post(process.env.API_URL + 'user/check-address-info', this.user).then((response) => {
            this.validate = response.data.shouldUpdate
          })
        }
      }
    },
    getHighestDateFromArray (dates) {
      let moments = dates.map(x => this.moment(x))
      var maxDate = this.moment.max(moments)
      return maxDate.format('YYYY-MM-DD')
    },
    getLowestDateFromArray (dates) {
      let moments = dates.map(x => this.moment(x))
      var minDate = this.moment.min(moments)
      return minDate.format('YYYY-MM-DD')
    },
    getTripStartDate () {
      let checkoutItems = {}
      let dates = []

      this.$store.state.cart.cartData.items.forEach(function (item, index) {
        checkoutItems[index] = item

        if (item.type === 'customtrip') {
          dates[index] = item.customRouter.initialDate
        } else {
          dates[index] = item.origin.date
        }
      })

      var lowestDate = this.getLowestDateFromArray(dates)
      var subDays = Number(this.$store.state.companyDetails.two_times_bankslip_due_days);
      return this.moment(lowestDate).subtract(subDays, 'days')
    },
    searchCep () {
      setTimeout(() => {
        if (this.credentials.cep && this.credentials.cep.length === 8) {
          this.blockAddressForm = true
          axios.get(`https://viacep.com.br/ws/${this.credentials.cep}/json/`)
            .then((response) => {
              this.data = response.data
              this.credentials.address = response.data.logradouro
              this.credentials.city = response.data.localidade
              this.credentials.neighborhood = response.data.bairro
              this.credentials.state = response.data.uf
              this.blockAddressForm = false
            })
            .catch((error) => {
              this.blockAddressForm = false
              this.$swal({
                title: 'Erro!',
                text: 'Não foi possível encontrar o CEP informado.',
                showDenyButton: false,
                timer: 2500,
                showConfirmButton: false,
                iconColor: this.$store.state.companyDetails.whitelabel.body_font_color,
                icon: 'error'
              })
           
            })
        }
      }, 500)
    },
    dayBeforeTrip () {
      let actualDate = this.moment()
      let two_times_bankslip_due_days = this.$store.state.companyDetails.two_times_bankslip_due_days
      let tripStartDate = this.getTripStartDate()
      let finalDate = this.moment(tripStartDate)
      let daysBeforeTrip = finalDate.diff(actualDate, 'days')

      this.tripStartDate = tripStartDate
      this.tripStartDateFormatted = tripStartDate.format('DD/MM/YYYY')
      this.differenceDays = daysBeforeTrip
      this.paymentHighestDate = finalDate.subtract(two_times_bankslip_due_days, 'days')
      this.paymentHighestDateFormated = finalDate.format('DD/MM/YYYY')

    },

    applyCoupon () {
      if (this.couponName !== '') {
        axios
          .get(process.env.API_URL + 'get-coupon', {
            params: {
              name: this.couponName,
              cart_total: this.$store.state.cart.cartData.total
            }
          })
          .then((response) => {
            delete this.errors.coupon

            if (response.status === 200) {
              this.$store.commit('cart/setCoupon', response.data)
              this.couponName = this.$store.state.cart.cartData.coupon.name
            }
          })
          .catch((error) => { console.log(error) })
      }
    },
    validateBankSlip () {
      if (this.methodPayment === 'bank_slip_parcel') {
        if (this.bank_slip_installment === null) {
          this.cc_errors.bank_slip_installment = 'Número de parcelas obrigatório'
        }
      }
    },
    sendOrder () {
      this.$store.commit('SET_LOADING_STATE', { value: true, text: 'Aguarde, estamos gerando sua reserva' })
      this.cc_errors = {}
      this.validateBankSlip()
      this.canDoCheckout = true
      // if(this.cardInfo.cardNumber != null) {
      //   this.$store.state.cart.cartData.credit_card_four_last_digits = this.formatCardNumber()
      // }

      if (this.$store.state.auth.userData == null) {
        this.$store.commit('SET_LOADING_STATE', { value: false })
        this.cc_errors.user = 'Entre com sua conta antes de prosseguir'
        this.$refs['login'].focus()
      }

      if (!this.agree_terms) {
        this.$store.commit('SET_LOADING_STATE', { value: false })
        this.cc_errors.agree_terms = 'Aceite os termos de uso antes de prosseguir'
      }

      if (!this.agree_service_terms) {
        this.$store.commit('SET_LOADING_STATE', { value: false })
        this.cc_errors.agree_service_terms = 'Aceite os termos de prestação de serviço antes de prosseguir'
      }
      // if (this.credentials.cpf == "") {
      //   this.$store.commit('SET_LOADING_STATE', {value: false})
      //   this.cc_errors.cpf = "CPF é obrigatório";
      // }

      this.$store.state.cart.cartData.user_id = this.$store.state.auth.userData.id
      this.$store.state.cart.cartData.customer_id = this.$store.state.auth.userData.id
      this.$store.state.cart.cartData.tripDetails = this.$store.state.tripDetails
      this.$store.state.cart.cartData.origin = this.$store.state.origin
      this.$store.state.cart.cartData.destination = this.$store.state.destination
      if(this.$store.state.auth.userData.type_people == "2"){
        this.$store.state.cart.cartData.cpf = this.$store.state.auth.userData.cnpj
      }else{
        this.$store.state.cart.cartData.cpf = this.credentials.cnpj_cpf
      }
      this.$store.state.cart.cartData.email = this.credentials.email

      if (this.installments != null) {
        this.$store.state.cart.cartData.installments = this.installments
      } else if (this.bank_slip_installment != null) {
        this.$store.state.cart.cartData.installments = this.bank_slip_installment
      }
      // if( this.credentials.cpf === null ){
      //   this.$store.commit('SET_LOADING_STATE', {value: false})
      //   this.cc_errors.cpf = true
      //   this.canDoCheckout = false
      // }
      // if( this.credentials.email === null ){
      //   this.$store.commit('SET_LOADING_STATE', {value: false})
      //   this.cc_errors.email = true
      //   this.canDoCheckout = false
      // }
      if (!this.canDoCheckout) {
        this.$store.commit('SET_LOADING_STATE', { value: false })
        let errorFields = ''

        Object.keys(this.cc_errors).map((item) => {
          errorFields += '<span class="required-field">' + item + ' </span>'
        })

        this.$swal({
          title: 'Erro!',
          html: 'Os seguintes campos são obrigatórios: <div class="error-fields">' + errorFields + '</div>',
          showDenyButton: false,
          timer: 2500,
          showConfirmButton: false,
          iconColor: this.$store.state.companyDetails.whitelabel.body_font_color,
          icon: 'error'
        })
      }
      if (Object.keys(this.cc_errors).length === 0 && this.canDoCheckout) {
     
        this.sendOrderRequest()
     
      }
    },
    termsAgree () {
      this.agree_terms = true
    },
    termsServiceAgree () {
      this.agree_service_terms = true
    },
    
    validateCPF(cpf) {
      var cpf = cpf.replace(/[^\d]/g, ''); 
      if (cpf.length !== 11 || /^(.)\1+$/.test(cpf)) {
        return false;
      }
      let sum = 0;
      let remainder;
      for (let i = 1; i <= 9; i++) {
        sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
      }
      remainder = (sum * 10) % 11;
      if (remainder === 10 || remainder === 11) {
        remainder = 0;
      }
      if (remainder !== parseInt(cpf.substring(9, 10))) {
        return false;
      }
      sum = 0;
      for (let i = 1; i <= 10; i++) {
        sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
      }
      remainder = (sum * 10) % 11;
      
      if (remainder === 10 || remainder === 11) {
        remainder = 0;
      }
      if (remainder !== parseInt(cpf.substring(10, 11))) {
        return false;
      }
      return true;
    },
    paymentMethod (method, validate) {
      if (validate) {
        if (method) {
          this.installments = null
          this.methodPayment = method
        }
        this.$store.commit('cart/setPaymentMethod', method)
      } else {
        this.$swal('Erro', 'A data da sua viagem não permite esta forma de pagamento', 'error')
      }
    },
    showModal: function (id) {
      this.$refs[id].show()
    },
    showLogin () {
      this.showLoginModal = true
    },
    closeModal () {
      this.showLoginModal = false
    },
    async getServiceTerms (total, items, customer) {
      await axios
        .post(process.env.API_URL + 'cart/terms/get-terms', {
          total: total,
          items: items,
          customer: customer
        })
        .then((response) => {
          this.$store.commit('updateServiceTerms', response.data)
        })
        .catch(() => {})
    },
    getBankSlipInstallments (data) {
      if (!this.$store.state.cart.cartData.items.length > 0) {
        return
      }
      axios
        .post(process.env.API_URL + 'cart/bank-slip/installments', data)
        .then((response) => {
          this.bank_slip_installments = response.data.installments
        })
        .catch(() => {})
    },
    setAuthAttempt (type) {
      this.auth_attempt = type
    },
    formatCardNumber () {
      return this.cardInfo.cardNumber.slice(-4)
    }
  },
  computed: {
    ...mapGetters(['errors']),
    ...mapGetters('auth', ['user']),
    cssProps () {
      return {
        '--default-color': this.$store.state.companyDetails.whitelabel.body_font_color
      }
    },
    primaryColor () {
      return this.$store.state.companyDetails.whitelabel.primaryColor
    },
    secondaryColor () {
      return this.$store.state.companyDetails.whitelabel.secondaryColor
    },
    formBackgroundColor () {
      return this.$store.state.companyDetails.whitelabel.form_background_color
    },
    formFontColor () {
      return this.$store.state.companyDetails.whitelabel.form_font_color
    },
    twoTimesBankslipUpfrontPercentageValue () {
      return this.$store.state.companyDetails.two_times_bankslip_upfront_payment
    },
    twoTimesBankslipLastPaymentDays () {
      return this.$store.state.companyDetails.two_times_bankslip_due_days
    }
  },
  beforeMount () {
    this.$store.commit('SET_LOADING_STATE', { value: false })
  },
  async mounted () {
    this.validateAddressData()
    await this.$store.commit('cart/setPaymentMethod', 'bank_slip')
    // this.$store.commit("cart/setClearCart", {})
    // this.$store.state.cart.cartData = {}
    let cartData = this.$store.state.cart.cartData.items
    let filtered = cartData.filter(function (item) { return item })
    if (cartData.length !== filtered.length) {
      this.$store.state.cart.cartData.items = filtered
    }

    this.$store.commit('setErrors', {})
    this.$store.commit('setResponseMessage', {})

    // await until have products in cart 
    while (this.$store.state.cart.cartData.items.length === 0) {
      await new Promise(resolve => setTimeout(resolve, 1000))
    }

    await this.$store.commit('cart/calculateCartTotal', { data: this.$store.state })

    this.dayBeforeTrip()
    window.scrollTo(0, 0)

    this.credentials.cnpj_cpf = this.user == null ? '' : this.user.cnpj_cpf
    this.credentials.email = this.user == null ? '' : this.user.email
    this.credentials.tel = this.user == null ? '' : this.user.tel
    await this.getBankSlipInstallments({
      origin_date: this.moment(this.tripStartDate).format('YYYY/MM/DD'),
      total: this.$store.state.cart.cartData.total,
      items: this.$store.state.cart.cartData.items
    })
    if (this.$store.state.cart.cartData.coupon) { this.couponName = this.$store.state.cart.cartData.coupon.name }

    await this.getServiceTerms(this.$store.state.cart.cartData.total, this.$store.state.cart.cartData.items, this.$store.state.auth.userData)

  }
}
</script>

<style>
  @import "../assets/scss/pages/_quill.css";
</style>

<style lang="scss">
  @import "../assets/scss/pages/_checkout.scss";
  @import "../assets/scss/components/_vehicle-card.scss";
 
  @media (max-width: 980px) {
    .col-image, .col-info, .col-price.text-right, .col-price.text-right *, .title, .configurations-wrapper {
      display: flex;
      align-items: center!important;
      justify-content: center!important;
      text-align: center!important;
      flex-direction: column;
    }

    .col-price {
      margin-top: 20px;
      flex-direction: column-reverse!important;
    }

    button.btn.btn-coupon {
        width: 100%;
        margin-top: 20px;
        margin-left: 0!important;
    }

    .checkout-total {
      padding: 0 35px!important;
    }

    .cart {
      padding: 10px 0;
      border-radius: 4px;
    }
    .checkout-total * {
      text-align: left!important;
    }

    .checkout-total .col-lg-8 .col-md-5.col-12 button {
      text-align: center;
    }
    .checkout-total .col-lg-8 .col-md-5.col-12 {
      padding: 0!important;
    }

  }

  #terms-of-service h4 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  #terms-of-service table tr td:first-child {
    width: 30%;
  }
  #terms-of-service header h5 {
    width: 100%;
    text-align: center;
    padding-left: 25px;
  }
  #terms-of-service  {
    table {
        width: 100%;
        border: 1px solid rgb(165, 165, 165);
      th {
        padding: 5px;
        width: 50%;
        text-align: left;
        border: 1px solid rgb(165, 165, 165);
      }
      td {
        padding: 5px;
        width: 50%;
        border: 1px solid rgb(165, 165, 165);
      }
    }
  }

  .configurations-wrapper {
    span {
      color: var(--default-color);
      border-color: var(--default-color);
    }
  }

  .block-address-form {
    width: 100%;
    height: 100%;
    background: #e0e0e082;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }

  .empty-list {
    min-height: calc(60vh + 6px);
    h5 {
      font-weight: 400;
    }
  }

  .btn-primary{
    background-color: var(--default-color);
    border-color: var(--default-color);
    &.disabled {
      opacity: .6;
      background-color: #8b8b8b;
      border-color: #8b8b8b;
      cursor: not-allowed;
    }
  }

  #checkout{
    .cta {
      h2 {
        color: white;
        font-size: 1.4rem;
      }

      .btn-outline-white {
        color: white;
        border-color: white;
        border: 1px solid;
        padding: 5px 15px;
        &:hover {
          color: var(--default-color);
          background-color: white;
        }
      }
      background-color: var(--default-color)
    }
  }

  .block-ui {
    filter: grayscale(1);
    opacity: .6;
    cursor: not-allowed;
  }

  .block-payment {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #cbcbcb66;
    z-index: 9;
    filter: grayscale(1);
  }

  .error-fields {

    margin-top: 10px;
    margin-bottom: 40px;

    .required-field {
      background: #ededed;
      margin-right: 10px;
      padding: 5px 10px;
    }
  }
  .payment-data{
    p {
      svg {
        color: var(--default-color);
        font-size: 32px;
        margin-right: 20px;
      }
    }

    .payment-methods {

      @media screen and (min-width: 1024px) {
        .payment:first-child{
          padding-right: 0;
        }
        .payment:not(:first-child) {
          padding-left:2px;
          padding-right:0px;
        }

      }
      .box {
        background-color: #ffffff;
        border-radius: 0.25rem;
        font-size: 14px;
        color: #8b8b8b;
        height: 60px;
        width: 100%;
        border: 1px solid #e8e8e8;
        padding: 10px 20px;
        text-align: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: -1px;
        z-index: 0;
        position: relative;

        &:hover {
          border-color: var(--default-color);
          background-color: rgb(255, 255, 255);
          color: var(--default-color);
          cursor: pointer;

        }

        &.active {
          border-color: var(--default-color);
          background-color: white;
          color: var(--default-color);
          z-index: 9;
          border-bottom: 0;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
          @media (max-width: 1024px) {
            border:1px solid;
          }
        }

        &.inactive {
          opacity: .5;
          cursor: not-allowed
        }
      }
    }

    .payment-information {
      .info {
        border: 1px solid;
        border-color: var(--default-color);
        padding: 30px 20px;
        margin-top: 0;
        font-size: 14px;
        border-radius: 5px;
        border-top-left-radius: 0;
      }
    }
  }

  .address-data {
    padding: 10px 30px;
  }

  .checkout-total {
    padding: 20px 60px;
    h1 {
        font-size: 22px;
    }
    h2 {
        font-size: 18px;
    }

    @media ( max-width: 980px) {
      padding: 20px 20px;

      .col-md-7 {
        padding: 0;
      }
    }
  }
  .card.payment-data{
    padding: 30px 40px !important;
  }

  @media(max-width: 1024px) {
    .card.payment-data{
      padding: 30px 15px !important;
    }
    .btn-coupon {
      margin-top: 10px;
    }
  }

  .modal-content {
    height: calc(100vh - 60px);
    overflow: auto;
  }
  
 .btn-coupon {
    background-color: white;
    border: 1px solid;
    border-color: var(--default-color);
    border-radius: 4px;
    font-weight: 400;
    display: block;
    width: 100%;
    color:  var(--default-color);
    padding: 10px!important;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    svg {
      path {
        fill: var(--default-color);
      }
    }

    &:hover {
        color: #fff !important;
        background: var(--default-color);;
    }
  }

  .btn-checkout {
    background-color: var(--default-color);
    border-color: var(--default-color);
    color: white;

    &:hover {
      opacity: .7
    }
  }

  .checkout-login {

    .btn {
      margin-bottom: 20px;
    }

    h4 {
      text-align: center;
      padding: 20px;
      color: #565656;
    }

    a {
      width: fit-content;
      padding: 10px 20px;
      border-radius: 5px;
      margin: 0 auto;
      border: 1px solid var(--default-color);
      color: var(--default-color);
      margin-bottom: 20px;

      &:hover {
        background-color: var(--default-color);
        color: white!important;
      }
    }
  }

  .cupom-details {
    padding: 10px 0px;
    .content {
      .name {
        font-weight: 500;
        font-size: 22px;
        span {
        color: black;
        font-weight: 500;
        font-size: 14px;
        }
      }
      .discount {
        text-align: right;
        font-size: 22px;
        span {
        color: black;
        font-weight: 500;
        font-size: 14px;
        }
      }
    }
  }
  .modalBtn{
    background: transparent;
    border: none;
  }
  .modalBtn:hover{
    color: #fff;
  }
  @media screen and(max-width:968px){
    #terms-of-use {
      padding-left: 0 !important;
    }
  }

  #terms-of-use, #terms-of-service {
    display: block;
    padding-left: 15px;

    table {
      min-width: 500px;
    }

    .modal-header {
      position: relative!important;
      right: 0px;
      top: 0px;
      z-index: 999999999;
    }

    .modal-body {
      background: white!important;
      margin: 0 20px;
      border-radius: 10px;
    }
    
  }

</style>
